<template>
  <div>
    <b-row v-if="refundData" class="row-eq-height" footer-tag="footer">
      <b-col class="mb-1" cols="6">
        <b-card title="Refund" class="h-100">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ refundData.id }}</td>
              </tr>
              <tr>
                <th class="text-left">Code</th>
                <td class="text-right">{{ refundData.code }}</td>
              </tr>
              <tr>
                <th class="text-left">Service Cost</th>
                <td class="text-right">{{ refundData.service_cost }} EGP</td>
              </tr>

              <tr>
                <th class="text-left">Refund Fees</th>
                <td class="text-right">{{ refundData.admin_fees }} EGP</td>
              </tr>
              <tr>
                <th class="text-left">Total Refund Transaction</th>
                <td class="text-right">
                  {{ refundData.transaction_total }} EGP
                </td>
              </tr>
              <tr>
                <th class="text-left">Status</th>
                <td class="text-right">
                  <b-badge variant="primary">
                    {{ refundData.status }}
                  </b-badge>
                </td>
              </tr>
            </tbody>
          </table>
          <template #footer v-if="refundData.status == 'pending'">
            <em>
              <b-row>
                <b-col cols="12">
                  <b-button
                    block
                    @click="modalShow = !modalShow"
                    v-b-modal.modal-change
                    variant="outline-warning"
                  >
                    Change Status
                  </b-button>

                  <b-modal
                    v-model="modalShow"
                    id="modal-change"
                    cancel-variant="outline-secondary"
                    ok-title="Change"
                    cancel-title="Close"
                    centered
                    title="Change Status"
                    @ok="changeStatus"
                  >
                    <b-form>
                      <validation-observer ref="simpleRules">
                        <validation-provider
                          #default="{ errors }"
                          name="Status"
                          rules="required"
                        >
                          <b-form-group
                            label="Status"
                            label-for="status"
                            :state="errors.length > 0 ? false : null"
                          >
                            <v-select
                              id=""
                              v-model="statusSelect"
                              :state="statusSelect == null ? false : true"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="refundSataus"
                              :selectable="
                                (option) =>
                                  !option.value.includes('select_value')
                              "
                              label="text"
                              placeholder="select status"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </validation-observer>
                    </b-form>
                  </b-modal>
                </b-col>
              </b-row>
            </em>
          </template>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card title="Orders" v-if="items.length != 0">
          <b-table
            id="OrdersTable"
            ref="admint"
            striped
            responsive
            class="position-relative"
            :items="items"
            :fields="fields"
          >
            <template #cell(orderstatus)="data">
              <b-badge variant="primary">
                {{ data.value.name }}
              </b-badge>
            </template>
            <template #cell(actions)="items">
              <div class="d-flex p-1">
                <!-- Show Order -->
                <b-button
                  v-if="authPermissions.includes('update')"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  type="submit"
                  :to="{ path: '/orders/show/' + items.item.id }"
                >
                  <feather-icon
                    style="width: 18px; height: 18px"
                    icon="EyeIcon"
                  />
                </b-button>
                <!-- update Order -->
                <b-button
                  v-if="authPermissions.includes('update')"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  type="submit"
                  :to="{ path: '/orders/Update/' + items.item.id }"
                >
                  <feather-icon
                    icon="EditIcon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
                <!-- update Order Status -->
                <b-button
                  size="xs"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  type="submit"
                  :to="{ path: '/orders/Update/Status/' + items.item.id }"
                >
                  change Status
                </b-button>
                <!-- delete Order -->
                <b-button
                  v-if="authPermissions.includes('delete')"
                  size="sm"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  type="submit"
                  @click="deleteData(items.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    style="width: 18px; height: 18px"
                  />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>

        <b-card title="Subscription" v-if="subscription.length != 0">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th class="text-left">ID</th>
                <td class="text-right">{{ subscription.id }}</td>
              </tr>
              <tr>
                <th class="text-left">Code</th>
                <td class="text-right">{{ subscription.code }}</td>
              </tr>
              <tr>
                <th class="text-left">Ads Limit</th>
                <td class="text-right" v-if="subscription.ads_limit">
                  {{ subscription.ads_limit }}
                </td>
              </tr>
              <tr>
                <th class="text-left">Status</th>
                <td class="text-right">
                  <b-badge variant="primary">
                    {{ subscription.subscription_status }}
                  </b-badge>
                </td>
              </tr>

              <tr>
                <th class="text-left">Date</th>
                <td class="text-right">
                  {{ subscription.created_at | formatDate }}
                </td>
              </tr>
              <tr v-if="subscription.delUser">
                <th class="text-left">Last Update Date</th>
                <td class="text-right">
                  {{ subscription.updated_at | formatDate }}
                </td>
              </tr>
              <tr v-else>
                <th class="text-left">Deleted Date</th>
                <td class="text-right">
                  {{ subscription.deleted_at | formatDate }}
                </td>
              </tr>

              <tr>
                <th class="text-left">Total Paid</th>
                <td class="text-right">{{ subscription.total_paid }}</td>
              </tr>

              <tr v-if="subscription.cart != null">
                <th class="text-left">payment gateway</th>
                <td class="text-right" dir="rtl">
                  <b-badge class="badge btn-primary">
                    {{ subscription.cart.payment_gateway_type }}</b-badge
                  >
                </td>
              </tr>
              <tr v-if="subscription.success_date != null">
                <th class="text-left">Success Date</th>
                <td class="text-right" dir="ltr">
                  {{ subscription.success_date }}
                </td>
              </tr>
              <tr v-if="subscription.delivered_date != null">
                <th class="text-left">Delivered Date</th>
                <td class="text-right" dir="ltr">
                  {{ subscription.delivered_date }}
                </td>
              </tr>
              <tr v-if="subscription.completed_date != null">
                <th class="text-left">Completed Date</th>
                <td class="text-right" dir="ltr">
                  {{ subscription.completed_date }}
                </td>
              </tr>
              <div class="ml-2">
                <p class="my-2"><b>package </b></p>

                <tbody class="ml-2" v-if="subscription.package_snapshot">
                  <tr>
                    <th class="text-left">ID</th>
                    <td class="text-right">
                      {{ JSON.parse(subscription.package_snapshot).id }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Name</th>
                    <td class="text-right">
                      {{
                        JSON.parse(
                          JSON.parse(subscription.package_snapshot).name
                        ).name_en
                      }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">ads_limit</th>
                    <td class="text-right">
                      {{ JSON.parse(subscription.package_snapshot).ads_limit }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">code</th>
                    <td class="text-right">
                      {{ JSON.parse(subscription.package_snapshot).code }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">is_active</th>
                    <td class="text-right">
                      {{ JSON.parse(subscription.package_snapshot).is_active }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">price</th>
                    <td class="text-right">
                      {{ JSON.parse(subscription.package_snapshot).price }}
                    </td>
                  </tr>
                </tbody>
              </div>
            </tbody>
          </table>
        </b-card>
        <b-card v-if="paymentSession.length != 0">
          <b-table 
            id="PaymentSessionsTable"
            ref="admint"
            striped
            responsive
            :items="paymentSession"
            :fields="paymentSessionFields"
            class="position-relative"
          >
            <template #cell(payment_provider)="data">
              <b-img-lazy width="40" :src="data.value.logo" />
            </template>

            <template #cell(payment_method)="data">
              {{ data.value.fees }} %
            </template>
            <template #cell(seller_username)="items">
              <router-link
                :to="{ path: '/User/Show/' + items.item.seller_id }"
                v-if="authPermissions.includes('user')"
                class="text-primary"
              >
                {{ items.item.seller_username }}
              </router-link>
              <p v-else>{{ items.item.seller_username }}</p>
            </template>
            <template #cell(buyer_username)="items">
              <router-link
                :to="{ path: '/User/Show/' + items.item.buyer_id }"
                v-if="authPermissions.includes('user')"
              >
                {{ items.item.buyer_username }}
              </router-link>
              <p v-else>{{ items.item.buyer_username }}</p>
            </template>
            <template #cell(status)="data">
              <b-badge
                :variant="
                  data.value == 'pending'
                    ? 'light-primary'
                    : data.value == 'delivered'
                    ? 'light-success'
                    : data.value == 'failed'
                    ? 'light-danger'
                    : 'light-secondary'
                "
              >
                {{ data.value }}
              </b-badge>
            </template>
            <template #cell(actions)="items">
              <div class="text-center d-flex p-1">
                <!-- Show Order -->
                <b-button
                  v-if="authPermissions.includes('show')"
                  variant="light-danger"
                  type="submit"
                  class="my-1 mx-0 pt-1 px-1"
                  :to="{ path: '/paymentSessions/show/' + items.item.id }"
                >
                  <img
                    width="30"
                    :src="require('@/assets/images/icons/eye.png')"
                  />
                </b-button>

                <b-button
                  v-if="
                    authPermissions.includes('show') &&
                    items.item.status == 'pending'
                  "
                  variant="light-danger"
                  type="submit"
                  class="my-1 mx-0 pt-1 px-1"
                  :to="{
                    path: '/paymentSessions/realtime/show/' + items.item.id,
                  }"
                >
                  <img
                    :src="require('@/assets/images/icons/live-streaming.svg')"
                  />
                </b-button>

                <!-- </b-button> -->
              </div>
            </template>
          </b-table>
        </b-card>
          
      </b-col>
    </b-row>

    <b-card title="Loggers" class="d-flex flex-wrap">
      <b-row>
        <b-col class="mt-3" cols="12">
          <app-timeline>
            <app-timeline-item
              v-for="(item, index) in refundData.refund_loggers"
              :key="index"
              :variant="item.admin != null ? 'primary' : 'success'"
            >
              <div
                class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
              >
                <h6>{{ item.note }}</h6>
              </div>
              <b-media v-if="item.admin != null">
                <template #aside>
                  <b-avatar :src="item.admin.profile_picture" />
                </template>
                <h6>{{ item.admin.firstname + ' ' + item.admin.lastname }}</h6>
                <p>{{ item.admin.role }}</p>
              </b-media>
              <b-media v-else>
                <template #aside>
                  <!-- <b-avatar :src="item.vendor.profile_picture" /> -->
                </template>
                <!-- <h6>{{item.vendor.storename}}</h6> -->
              </b-media>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
    </b-card>

    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>
<script>
import { heightTransition } from '@core/mixins/ui/transition'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import { required } from '@validations'
export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  mixins: [heightTransition],
  data() {
    return {
      authPermissions: null,
      modalShow: false,
      orderStatus: '',
      showDismissibleAlert: false,
      id: 0,
      errors_back: [],
      required,
      refundData: {},
      orderData: [],
      // table fields
      paymentSessionFields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'buyer_username',
          label: 'Buyer Username',
        },
        {
          key: 'seller_username',
          label: 'Seller Username',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'total_paid',
          label: 'Total',
        },
        {
          key: 'margin_amount',
          label: 'Margin Amount',
        },
        {
          key: 'payout_amount',
          label: 'Payout Amount',
        },
        {
          key: 'payment_provider',
          label: 'Payment Provider',
        },
        {
          key: 'payment_method',
          label: 'Payment Fees',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],
      // table fields
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },

        {
          key: 'code',
          label: 'Code',
        },
        {
          key: 'orderstatus',
          label: 'Order Status',
        },
        {
          key: 'total',
          label: 'Total',
        },
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'discount',
          label: 'Discount',
        },
        {
          key: 'shipping_price',
          label: 'Shipping Price',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },
        {
          key: 'actions',
        },
      ],

      fieldsSubs: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'code',
        },

        {
          key: 'user',
          label: 'User Name',
        },
        {
          key: 'packages',
          label: 'packages Name',
        },

        {
          key: 'subscription_status',
          label: 'Status',
        },
        {
          key: 'payment_provider',
          label: 'Payment Provider',
        },
        {
          key: 'total_paid',
          label: 'total_paid',
        },
        {
          key: 'created_at',
          label: 'Created_At',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated_At',
          sortable: true,
        },

        {
          key: 'actions',
          label: 'actions',
        },
      ],

      items: [],
      subscription: [],
      paymentSession: [],

      statusSelect: null,
      refundSataus: [
        {
          text: 'Cancel',
          value: 'cancel',
        },
        {
          text: 'Accept',
          value: 'accept',
        },
      ],
    }
  },
  created() {
    // request status data
    this.authPermissions = this.$store.state.authPermissions
    this.id = this.$route.params.id
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios
        .get('refunds/' + this.id)
        .then((result) => {
          this.refundData = result.data.data
          this.items = this.refundData.orders
          this.paymentSession = []
          this.subscription = []
          if (this.refundData.subscription != null) {
            this.subscription = this.refundData.subscription
          }
          if (this.refundData.payment_session != null) {
            this.paymentSession.push(this.refundData.payment_session)
          }
          
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    Deattach(id, quantity) {
      axios
        .post('refunds/' + this.id + '/variants/' + id + '/detach', {
          quantity: quantity,
        })
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    Attach(id, quantity) {
      axios
        .post('refunds/' + this.id + '/variants/' + id + '/attach', {
          quantity: quantity,
        })
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    changeStatus() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.modalShow = false
          axios
            .get(
              'refunds/' +
                this.id +
                '/status/' +
                this.statusSelect.value +
                '/change'
            )
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.fetchData()
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style lang="scss">
</style>
